module.exports = {
  title: 'Simplicity',
  logo: '',
  url: 'https://simplicitycommerce.com',
  favicon: '../common/static/favicon/favicon.png',
  cover: '',
  legalName: 'Simplicity Commerce',
  description: '',
  socialLinks: {
    twitter: '',
    github: '',
    linkedin: '',
    instagram: '',
    youtube: '',
    google: '',
  },
  googleAnalyticsID: '',
  themeColor: '#9156bc',
  backgroundColor: '#9156bc',
  social: {
    facebook: '',
    twitter: '',
  },
  address: {
    city: '',
    region: '',
    country: '',
    zipCode: '',
  },
  contact: {
    email: '',
    phone: '',
  },
  foundingDate: '2019',
  googleVerification: 'xxx',
};
